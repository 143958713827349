<template>
    <button 
        class="button"
        @click="emit"
        :disabled="loading"
        :class="{
            'button--primary': primary,
            'button--secondary': secondary,
            'button--inline': inline,
            'button--full-mobile': fullMobile,
        }"
    >
        <slot v-if="!loading" />
        <div v-else class="loader" />
    </button>
</template>

<script>
export default {
    props: {
        primary: {
            type: Boolean,
            default: false
        },

        secondary: {
            type: Boolean,
            default: false
        },

        inline: {
            type: Boolean,
            default: false
        },

        loading: {
            type: Boolean,
            default: false,
        },

        fullMobile: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        emit() {
            this.$emit('tapped')
        }
    }
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'futura';
    src: url('~@/assets/fonts/futuram.woff');
}

@font-face {
    font-family: 'futura-bold';
    src: url('~@/assets/fonts/futurab.woff');
}
.button {
    display: inline-block;
    position: relative;
    color: $white;
    padding: rem(15) rem(70);
    border: 0;
    font-size: rem(18);
    font-family: 'Futura-Medium', 'futura';
    transition: 0.3s;

  

    &--primary {
        background: $button-primary;

        &:hover {
            background: darken( $button-primary, 15%);
            cursor: pointer;
        }

        &:disabled {
            background: lighten( $button-primary, 15%);
        }
    }

    &--secondary {
        background: darken( $confirmation, 10%);
        &:hover {
            background: darken( $confirmation, 25%);
            cursor: pointer;
        }

        &:disabled {
            background: $confirmation;
        }
    }

    &--full-mobile {
        @media(max-width: $mobile-bp) {
            width: 100%;
            display: block;
        }
    }

    &--inline {
        display: inline-block;
    }
}
</style>